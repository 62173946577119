import { ActionCreator } from 'redux';
import { beginActivity, endActivity, setError } from 'store/request';
import { Thunk } from 'store/types';
import { v4 as uuidv4 } from 'uuid';
import { ActionTypes, getStripe, stripeData } from './types';

export const getStripeAction: ActionCreator<getStripe> = (
  data: stripeData
) => ({
  type: ActionTypes.GET_STRIPE,
  payload: {
    data,
  },
});

export const getStripeData = (): Thunk => async (
  dispatch,
  getState,
  context
) => {
  const activityId = uuidv4();
  try {
    await dispatch(
      beginActivity({
        type: ActionTypes.GET_STRIPE,
        uuid: activityId,
      })
    );
    const stripe = await context.api.data
      .stripe()
      .reasonForAccountDeactivation(localStorage.getItem('token'));

    dispatch(getStripeAction(stripe));
  } catch (error) {
    await dispatch(
      setError({
        type: ActionTypes.GET_STRIPE,
        error: error.message,
        uuid: uuidv4(),
      })
    );
  } finally {
    await dispatch(endActivity({ uuid: activityId }));
  }
};
