import {
  Category,
  County,
  Delivery,
  DemandLikeInformation,
  Demands,
  Label,
  Likes,
  MapPosition,
  Producers,
  Unit,
} from 'api/map';
// import Geocode from 'react-geocode';
import { ActionCreator } from 'redux';
import { beginActivity, endActivity, setError } from 'store/request';
import { Thunk } from 'store/types';
import { initialFilters } from 'store/utils/filter.utils';
import { v4 as uuidv4 } from 'uuid';
import {
  ClearEnhancedFilters,
  EnhancedFilters,
  GetCountiesAction,
  Products,
  SetEnhancedFilters,
} from '.';
import { getGeolocationInformation } from './helpers';
import {
  ActionTypes,
  Filters,
  GetCategoriesAction,
  GetCommunityProductsAction,
  GetDeliveriesAction,
  GetDemands2,
  GetEnhancedFiltersAction,
  GetLabels,
  GetProducersAction,
  GetProductsAction,
  GetProductsByCategoryAction,
  GetUnitsAction,
  MapInformation,
  SetFilters,
  SetLikeInformation,
  SetMapInformation,
} from './types';

// PRODUCERS REQUEST
export const getProducersAction: ActionCreator<GetProducersAction> = (
  data: Producers[],
  position: MapPosition
) => ({
  type: ActionTypes.GET_PRODUCERS,
  payload: {
    data,
    position,
  },
});

export const getProducers = (newPosition?: MapPosition): Thunk => async (
  dispatch,
  getState,
  context
) => {
  const activityId = uuidv4();

  try {
    await dispatch(
      beginActivity({
        type: ActionTypes.GET_PRODUCERS,
        uuid: activityId,
      })
    );

    const positionStore = await getGeolocationInformation(newPosition);

    const producersPromise = new Promise((resolve, reject) => {
      return resolve(
        context.api.data.map().getProducers({
          longitude: positionStore.lng,
          latitude: positionStore.lat,
          km: positionStore.km,
        })
      );
    });

    const productsPromise = new Promise((resolve, reject) => {
      return resolve(
        context.api.data.map().getProducts({
          longitude: positionStore.lng,
          latitude: positionStore.lat,
          km: positionStore.km,
        })
      );
    });

    const data = await Promise.all([producersPromise, productsPromise]);
    const producers = data[0] || [];
    const products = data[1] || [];

    if (producers) {
      dispatch(
        getProducersAction(producers, {
          longitude: positionStore.lng,
          latitude: positionStore.lat,
          km: positionStore.km,
        })
      );
      dispatch(
        setMapInformation({
          range: positionStore.km,
          positionString: positionStore.stringAddress,
        })
      );
    }

    if (products) {
      dispatch(
        getProductsAction(products, {
          longitude: positionStore.lng,
          latitude: positionStore.lat,
          km: positionStore.km,
        })
      );
    }

    // dispatch local store action
  } catch (error) {
    await dispatch(
      setError({
        type: ActionTypes.GET_PRODUCERS,
        error: error.message,
        uuid: uuidv4(),
      })
    );
  } finally {
    await dispatch(endActivity({ uuid: activityId }));
  }
};

// PRODUCTS REQUEST
export const getProductsAction: ActionCreator<GetProductsAction> = (
  data: Products[],
  position: MapPosition
) => ({
  type: ActionTypes.GET_PRODUCTS,
  payload: {
    data,
    position,
  },
});

export const getProducts = (newPosition?: MapPosition): Thunk => async (
  dispatch,
  getState,
  context
) => {
  const activityId = uuidv4();

  try {
    await dispatch(
      beginActivity({
        type: ActionTypes.GET_PRODUCTS,
        uuid: activityId,
      })
    );

    const positionStore = await getGeolocationInformation(newPosition);

    const producersPromise = new Promise((resolve, reject) => {
      return resolve(
        context.api.data.map().getProducers({
          longitude: positionStore.lng,
          latitude: positionStore.lat,
          km: positionStore.km,
        })
      );
    });

    const productsPromise = new Promise((resolve, reject) => {
      return resolve(
        context.api.data.map().getProducts({
          longitude: positionStore.lng,
          latitude: positionStore.lat,
          km: positionStore.km,
        })
      );
    });

    const data = await Promise.all([producersPromise, productsPromise]);
    const producers = data[0] || [];
    const products = data[1] || [];

    if (products) {
      dispatch(
        getProductsAction(products, {
          longitude: positionStore.lng,
          latitude: positionStore.lat,
          km: positionStore.km,
        })
      );
      dispatch(
        setMapInformation({
          range: positionStore.km,
          positionString: positionStore.stringAddress,
        })
      );
    }

    if (producers) {
      dispatch(
        getProducersAction(producers, {
          longitude: positionStore.lng,
          latitude: positionStore.lat,
          km: positionStore.km,
        })
      );
    }

    // dispatch local store action
  } catch (error) {
    await dispatch(
      setError({
        type: ActionTypes.GET_PRODUCTS,
        error: error.message,
        uuid: uuidv4(),
      })
    );
  } finally {
    await dispatch(endActivity({ uuid: activityId }));
  }
};

// DEMANDS REQUEST

export const getDemands: ActionCreator<GetDemands2> = (data: Demands[]) => ({
  type: ActionTypes.GET_DEMANDS2,
  payload: {
    data,
  },
});

export const getAllDemands = (): Thunk => async (
  dispatch,
  getState,
  context
) => {
  const activityId = uuidv4();
  try {
    await dispatch(
      beginActivity({
        type: ActionTypes.GET_DEMANDS2,
        uuid: activityId,
      })
    );
    const token = localStorage.getItem('token');
    const demands = await context.api.data.map().getDemands(token);
    dispatch(getDemands(demands));
  } catch (error) {
    await dispatch(
      setError({
        type: ActionTypes.GET_DEMANDS2,
        error: error.message,
        uuid: uuidv4(),
      })
    );
  } finally {
    await dispatch(endActivity({ uuid: activityId }));
  }
};

// Labels Request

export const getLabels: ActionCreator<GetLabels> = (data: Label[]) => ({
  type: ActionTypes.GET_LABELS,
  payload: {
    data,
  },
});

export const getAllLabels = (): Thunk => async (
  dispatch,
  getState,
  context
) => {
  const activityId = uuidv4();
  try {
    await dispatch(
      beginActivity({
        type: ActionTypes.GET_LABELS,
        uuid: activityId,
      })
    );
    const labels = await context.api.data.map().getLabels();
    dispatch(getLabels(labels));
  } catch (error) {
    await dispatch(
      setError({
        type: ActionTypes.GET_LABELS,
        error: error.message,
        uuid: uuidv4(),
      })
    );
  } finally {
    await dispatch(endActivity({ uuid: activityId }));
  }
};

// COMMUNITY PRODUCTS REQUEST
export const getCommunityProductsAction: ActionCreator<GetCommunityProductsAction> = (
  data: Products[],
  position: MapPosition
) => ({
  type: ActionTypes.GET_COMMUNITY_PRODUCTS,
  payload: {
    data,
    position,
  },
});

export const getCommunityProducts = (code: string): Thunk => async (
  dispatch,
  getState,
  context
) => {
  const activityId = uuidv4();

  try {
    await dispatch(
      beginActivity({
        type: ActionTypes.GET_COMMUNITY_PRODUCTS,
        uuid: activityId,
      })
    );

    const positionStore = await getGeolocationInformation();

    const products = await context.api.data.map().getCommunityProducts(code);
    if (products) {
      dispatch(
        getCommunityProductsAction(products, {
          longitude: positionStore.lng,
          latitude: positionStore.lat,
          km: positionStore.km,
        })
      );
      dispatch(
        setMapInformation({
          range: positionStore.km,
          positionString: positionStore.stringAddress,
        })
      );
    }

    // dispatch local store action
  } catch (error) {
    await dispatch(
      setError({
        type: ActionTypes.GET_PRODUCTS,
        error: error.message,
        uuid: uuidv4(),
      })
    );
  } finally {
    await dispatch(endActivity({ uuid: activityId }));
  }
};

//CATEGORIES REQUEST
export const getCategoriesAction: ActionCreator<GetCategoriesAction> = (
  data: Category[]
) => ({
  type: ActionTypes.GET_CATEGORIES,
  payload: {
    data,
  },
});

export const getCategories = (): Thunk => async (
  dispatch,
  getState,
  context
) => {
  const activityId = uuidv4();

  try {
    await dispatch(
      beginActivity({
        type: ActionTypes.GET_CATEGORIES,
        uuid: activityId,
      })
    );
    const categories = await context.api.data.map()['getCategories']();
    if (categories) {
      dispatch(getCategoriesAction(categories));
    }

    // dispatch local store action
  } catch (error) {
    await dispatch(
      setError({
        type: ActionTypes.GET_CATEGORIES,
        error: error.message,
        uuid: uuidv4(),
      })
    );
  } finally {
    await dispatch(endActivity({ uuid: activityId }));
  }
};

export const getCountiesAction: ActionCreator<GetCountiesAction> = (
  data: County[]
) => ({
  type: ActionTypes.GET_COUNTIES,
  payload: {
    data,
  },
});

export const getCounties = (): Thunk => async (dispatch, getState, context) => {
  const activityId = uuidv4();

  try {
    await dispatch(
      beginActivity({
        type: ActionTypes.GET_COUNTIES,
        uuid: activityId,
      })
    );
    const counties = await context.api.data
      .map()
      .getCounties(
        localStorage.getItem('token') ? localStorage.getItem('token') : null
      );
    if (counties) {
      dispatch(getCountiesAction(counties));
    }

    // dispatch local store action
  } catch (error) {
    await dispatch(
      setError({
        type: ActionTypes.GET_COUNTIES,
        error: error.message,
        uuid: uuidv4(),
      })
    );
  } finally {
    await dispatch(endActivity({ uuid: activityId }));
  }
};

//CATEGORIES REQUEST
export const getDeliveriesAction: ActionCreator<GetDeliveriesAction> = (
  data: Delivery[]
) => ({
  type: ActionTypes.GET_DELIVERIES,
  payload: {
    data,
  },
});

export const getDeliveries = (): Thunk => async (
  dispatch,
  getState,
  context
) => {
  const activityId = uuidv4();

  try {
    await dispatch(
      beginActivity({
        type: ActionTypes.GET_DELIVERIES,
        uuid: activityId,
      })
    );

    const deliveries = await context.api.data.map().getDeliveryType();
    if (deliveries) {
      dispatch(getDeliveriesAction(deliveries));
    }

    // dispatch local store action
  } catch (error) {
    await dispatch(
      setError({
        type: ActionTypes.GET_DELIVERIES,
        error: error.message,
        uuid: uuidv4(),
      })
    );
  } finally {
    await dispatch(endActivity({ uuid: activityId }));
  }
};

//CATEGORIES REQUEST
export const getUnitsAction: ActionCreator<GetUnitsAction> = (
  data: Unit[]
) => ({
  type: ActionTypes.GET_UNITS,
  payload: {
    data,
  },
});

export const getUnits = (): Thunk => async (dispatch, getState, context) => {
  const activityId = uuidv4();

  try {
    await dispatch(
      beginActivity({
        type: ActionTypes.GET_UNITS,
        uuid: activityId,
      })
    );

    const units = await context.api.data.map().getUnits();
    if (units) {
      dispatch(getUnitsAction(units));
    }

    // dispatch local store action
  } catch (error) {
    await dispatch(
      setError({
        type: ActionTypes.GET_UNITS,
        error: error.message,
        uuid: uuidv4(),
      })
    );
  } finally {
    await dispatch(endActivity({ uuid: activityId }));
  }
};

//PRODUCTS BY CATEGORY
export const getProductsByCategoryAction: ActionCreator<GetProductsByCategoryAction> = (
  data: Products[],
  position: MapPosition
) => ({
  type: ActionTypes.GET_PRODUCTS_BY_CATEGORY,
  payload: {
    data,
    position,
  },
});

export const getProductsByCategory = (
  categories: number[],
  positionStore?: {
    lat: number;
    lng: number;
    stringAddress: string;
    km: number;
  }
): Thunk => async (dispatch, getState, context) => {
  const activityId = uuidv4();

  try {
    await dispatch(
      beginActivity({
        type: ActionTypes.GET_PRODUCTS_BY_CATEGORY,
        uuid: activityId,
      })
    );

    const defaultPosition = {
      latitude: 45.8266751301338,
      longitude: 25.13688627812501,
    };

    const products = await context.api.data.map().getCategoriesProducts(
      categories,
      positionStore
        ? {
            longitude: positionStore.lng,
            latitude: positionStore.lat,
            km: positionStore.km,
          }
        : undefined
    );

    // dispatch local store action
    if (products) {
      dispatch(
        getProductsByCategoryAction(
          products,
          positionStore
            ? {
                longitude: positionStore.lng,
                latitude: positionStore.lat,
                km: positionStore.km,
              }
            : defaultPosition
        )
      );
      positionStore &&
        dispatch(
          setMapInformation({
            range: positionStore.km,
            positionString: positionStore.stringAddress,
          })
        );
    }
  } catch (error) {
    await dispatch(
      setError({
        type: ActionTypes.GET_PRODUCTS_BY_CATEGORY,
        error: error.message,
        uuid: uuidv4(),
      })
    );
  } finally {
    await dispatch(endActivity({ uuid: activityId }));
  }
};

export const setFilters: ActionCreator<SetFilters> = (
  filters: Partial<Filters>
) => ({
  type: ActionTypes.SET_FILTERS,
  payload: {
    filters,
  },
});

export const setMapInformation: ActionCreator<SetMapInformation> = (
  mapInformation: Partial<MapInformation>
) => ({
  type: ActionTypes.SET_MAP_INFO,
  payload: {
    mapInformation,
  },
});

// ENHANCED FILTERS REQUEST

export const getEnhancedFiltersAction: ActionCreator<GetEnhancedFiltersAction> = (
  data: EnhancedFilters[]
) => ({
  type: ActionTypes.GET_ENHANCED_FILTERS,
  payload: {
    data,
  },
});

export const getEnhancedFilters = (): Thunk => async (
  dispatch,
  getState,
  context
) => {
  const activityId = uuidv4();

  try {
    await dispatch(
      beginActivity({
        type: ActionTypes.GET_ENHANCED_FILTERS,
        uuid: activityId,
      })
    );

    for (const [, filter] of Object.entries(initialFilters)) {
      filter.defaultValues =
        filter.call && (await context.api.data.map()[filter.call]());
    }

    dispatch(getEnhancedFiltersAction(initialFilters));

    // dispatch local store action
  } catch (error) {
    await dispatch(
      setError({
        type: ActionTypes.GET_ENHANCED_FILTERS,
        error: error.message,
        uuid: uuidv4(),
      })
    );
  } finally {
    await dispatch(endActivity({ uuid: activityId }));
  }
};

export const setEnhancedFilters: ActionCreator<SetEnhancedFilters> = (
  data: Partial<EnhancedFilters>,
  filterName: string
) => ({
  type: ActionTypes.SET_ENHANCED_FILTERS,
  payload: {
    data,
    filterName,
  },
});

export const clearEnhancedFilters: ActionCreator<ClearEnhancedFilters> = () => ({
  type: ActionTypes.CLEAR_ENHANCED_FILTERS,
});

export const setLikeAction: ActionCreator<SetLikeInformation> = (
  likeState: number,
  demandId: number,
  id: number,
  firstName: string,
  lastName: string,
  alias: string,
  pictureUrl: string
) => ({
  type: ActionTypes.SET_LIKE_INFORMATION,
  payload: {
    likeState,
    demandId,
    id,
    firstName,
    lastName,
    alias,
    pictureUrl,
  },
});

export const giveLike = (
  data: DemandLikeInformation,
  userObj: Likes
): Thunk => async (dispatch, getState, context) => {
  const activityId = uuidv4();
  try {
    await dispatch(
      beginActivity({
        type: ActionTypes.LIKE_DEMAND,
        uuid: activityId,
      })
    );

    const token = localStorage.getItem('token');
    if (token) {
      const like = await context.api.data.map().giveLike(token, data);
      const likeState = typeof like === typeof {} ? 1 : 0;
      dispatch(
        setLikeAction(
          likeState,
          data.demandId,
          userObj.id,
          userObj.firstName,
          userObj.lastName,
          userObj.alias,
          userObj.pictureUrl
        )
      );
    }
  } catch (error) {
    await dispatch(
      setError({
        type: ActionTypes.LIKE_DEMAND,
        error: error.message,
        uuid: uuidv4(),
      })
    );
  } finally {
    await dispatch(endActivity({ uuid: activityId }));
  }
};
