import { Action } from 'redux';

export interface State {
  message: string;
  redirect: string;
  reason: string;
  button: string;
  isEmailConfirmed: number;
}

export enum ActionTypes {
  GET_STRIPE = 'store/stripe/GET_STRIPE',
}

export interface stripeData {
  nullBillingData: boolean;
  paymentType: string;
  timeStamp: number;
  button: string;
  isEmailConfirmed: number;
}

export interface getStripe extends Action {
  type: ActionTypes.GET_STRIPE;
  payload: {
    data: stripeData;
  };
}

export type Actions = getStripe;
