import { Action } from 'redux';

export interface State {
  termsAndConditions: {
    title: string;
    description: string;
  };
  pages: Array<{}>;
}

export interface Partners {
  id: number;
  url: string;
  image: {
    data: {
      id: 1;
      attributes: {
        name: string;
        url: string;
      };
    };
  };
}

export interface Pages {
  data: PagesData[];
  meta: {
    pagination: {
      page: number;
      pageSize: number;
      pageCount: number;
      total: number;
    };
  };
}

export interface PagesData {
  id: number;
  attributes: {
    title: string;
    description: string;
    slug: string;
    partners: Partners;
    icon: string;
    order: number;
  };
}

export enum ActionTypes {
  GET_PARTNERS = 'store/infoPages/GET_PARTNERS',
  GET_TERMS_CONDITIONS = 'store/infoPages/GET_TERMS_CONDITIONS',
  GET_PAGES = 'store/infoPages/GET_PAGES',
}

export interface partnersData {
  title: string;
  allPartners: Partners[];
}

export interface termsConditionsData {
  title: string;
  description: string;
}

export interface TermsAndConditions {
  data: {
    id: number;
    attributes: {
      title: string;
      description: string;
    };
  };
}
export interface getPartners extends Action {
  type: ActionTypes.GET_PARTNERS;
  payload: {
    data: partnersData;
  };
}
export interface getTermsAndConditions extends Action {
  type: ActionTypes.GET_TERMS_CONDITIONS;
  payload: {
    data: TermsAndConditions;
  };
}

export interface getPages extends Action {
  type: ActionTypes.GET_PAGES;
  payload: {
    data: Pages;
  };
}

export type Actions = getPartners | getTermsAndConditions | getPages;
