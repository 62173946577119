import { State } from './types';

const initialState: State = {
  message: '',
  redirect: '',
  reason: '',
  button: '',
  isEmailConfirmed: -1,
};

export default initialState;
