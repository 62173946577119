import {
  Category,
  Delivery,
  DemandLikeInformation,
  Label,
  MapPosition,
  Producers,
  Unit,
  DeliveryType,
} from 'api/map';
import { Action } from 'redux';

export interface Details {
  id: number;
  firstName: string;
  lastName: string;
  alias: string;
  description: string;
  phone?: string;
  email?: string;
  address?: string;
  longitude: number;
  latitude: number;
  reviewsReceivedNumber: number;
  reviewsReceivedPoints: number;
  overallRating: number;
  deliveryTypeId: number;
  pictureUrl: string;
  affiliateCode: string;
  distance: number;
  DeliveryType?: DeliveryType;
}

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  pictureUrl: string;
  alias: string;
  affiliateCode: string;
  isConfirmed: number;
  isSuspended: number;
  termsAndConditions: number;
  newsletter: number;
}

export interface Products {
  id: number;
  name: string;
  price: number;
  description: string;
  quantity: number;
  Category: Category;
  MeasurementUnit: Unit;
  User: Details;
  createdAt: string;
}

//1
export interface Demands {
  id: number;
  name: string;
  categoryId: number;
  description: string;
  phone: string;
  quantity: string;
  price: string;
  userId: number;
  isAvailable: number;
  isDeleted: number;
  createdAt: string;
  updatedAt: string;
  User: User;
  Category: Category;
  counties: County[];
  likes: Likes[];
  liked: Likes[];
}

export interface Likes {
  id: number;
  firstName: string;
  lastName: string;
  alias: string;
  pictureUrl: string;
}
export interface County {
  id: number;
  name: string;
  isDeleted: number;
  createdAt: string;
  updatedAt: string;
  DemandCounty: DemandCounty;
}
export interface DemandCounty {
  demandId: number;
  countyId: number;
  createdAt: string;
  updatedAt: string;
}

export interface ProducerInformation {
  details: Details;
  products: Products[];
  id?: string;
}

export interface Producer {
  [id: number]: ProducerInformation;
}

export interface Filters {
  categories: Category[];
  subcategories: Category[];
  marketCategories: MarketCategory[];
  units: Unit[];
  deliveries: Delivery[];
  lowPrice: number;
  highPrice: number;
  lowPriceMarket: number;
  highPriceMarket: number;
  lowStock: number;
  highStock: number;
  counties: Partial<County[]>;
  keyword: string;
  order: string;
}

export interface Range<T> {
  min: T;
  max: T;
}

export enum FilterType {
  CheckBox = 'checkbox',
  Radio = 'radio',
  Select = 'select',
  MultiSelect = 'multiSelect',
  GroupMultiSelect = 'groupMultiSelect',
  Range = 'range',
  Input = 'input',
}

interface PageType {
  [route: string]: FilterType | null;
}

export type CustomValues = Category[] | Unit[] | Delivery[] | County[];
export type ValueType =
  | null
  | string
  | string[]
  | Range<number | string>
  | CustomValues;

export interface EnhancedFilter {
  filterId: number;
  name: string;
  filterVisibleType: FilterType;
  value: ValueType;
  pages: PageType;
  defaultValues?: ValueType;
  call?: string;
}

export interface EnhancedFilters {
  [name: string]: EnhancedFilter;
}

export interface MarketCategory {
  subCat: string;
  cat: string;
}

export interface MapInformation {
  positionString: string;
  range: number;
}

export interface State {
  position: MapPosition;
  producers: Producer | undefined;
  filters: Filters;
  enhancedFilters: EnhancedFilters[];
  categories: Category[];
  labels: Label[];
  products: Products[] | undefined;
  productsByCategory: Products[] | undefined;
  demands: Demands[] | undefined;
  communityProducts: Products[] | undefined;
  units: Unit[];
  deliveries: Delivery[];
  mapInformation: MapInformation;
  counties: County[] | undefined;
}

export enum ActionTypes {
  GET_MAP_INFROMATIONS = 'store/map/GET_MAP_INFORMATIONS',
  GET_PRODUCERS = 'store/map/GET_PRODUCERS',
  GET_DEMANDS2 = 'store/map/GET_DEMANDS2',
  GET_LABELS = 'store/map/GET_LABELS',

  GET_PRODUCTS = 'store/map/GET_PRODUCTS',
  GET_PRODUCTS_BY_CATEGORY = 'store/map/GET_PRODUCTS_BY_CATEGORY',
  GET_COMMUNITY_PRODUCTS = 'store/map/GET_COMMUNITY_PRODUCTS',

  GET_DELIVERIES = 'store/map/GET_DELIVERIES',
  GET_CATEGORIES = 'store/map/GET_CATEGORIES',
  GET_COUNTIES = 'store/map/GET_COUNTIES',
  GET_UNITS = 'store/map/GET_UNITS',
  GET_ENHANCED_FILTERS = 'store/map/GET_ENHANCED_FILTERS',
  SET_ENHANCED_FILTERS = 'store/map/SET_ENHANCED_FILTERS',
  CLEAR_ENHANCED_FILTERS = 'store/map/CLEAR_ENHANCED_FILTERS',
  SET_FILTERS = 'store/map/SET_FILTERS',
  SET_POSITION = 'store/map/SET_POSITION',
  SET_MAP_INFO = 'store/map/SET_MAP_INFO',

  LIKE_DEMAND = 'storestore/map/LIKE_DEMAND',
  UPDATE_LIKE_INFORMATION = 'store/account/UPDATE_LIKE_INFORMATION',
  SET_LIKE_INFORMATION = 'store/map/SET_LIKE_INFORMATION',
}

export interface GetMapInformationsAction extends Action {
  type: ActionTypes.GET_MAP_INFROMATIONS;
  payload: {
    username?: string;
  };
}

export interface GetProducersAction extends Action {
  type: ActionTypes.GET_PRODUCERS;
  payload: {
    data: Producers[];
    position: MapPosition;
  };
}

export interface GetProductsAction extends Action {
  type: ActionTypes.GET_PRODUCTS;
  payload: {
    data: Products[];
    position: MapPosition;
  };
}

export interface GetProductsByCategoryAction extends Action {
  type: ActionTypes.GET_PRODUCTS_BY_CATEGORY;
  payload: {
    data: Products[];
    position: MapPosition;
  };
}

export interface GetDemands2 extends Action {
  type: ActionTypes.GET_DEMANDS2;
  payload: {
    data: Demands[];
  };
}
export interface GetLabels extends Action {
  type: ActionTypes.GET_LABELS;
  payload: {
    data: Label[];
  };
}

export interface GetCommunityProductsAction extends Action {
  type: ActionTypes.GET_COMMUNITY_PRODUCTS;
  payload: {
    data: Products[];
    position: MapPosition;
  };
}

export interface GetCategoriesAction extends Action {
  type: ActionTypes.GET_CATEGORIES;
  payload: {
    data: Category[];
  };
}

export interface GetCountiesAction extends Action {
  type: ActionTypes.GET_COUNTIES;
  payload: {
    data: County[];
  };
}

export interface GetDeliveriesAction extends Action {
  type: ActionTypes.GET_DELIVERIES;
  payload: {
    data: Delivery[];
  };
}

export interface GetUnitsAction extends Action {
  type: ActionTypes.GET_UNITS;
  payload: {
    data: Unit[];
  };
}

export interface GetEnhancedFiltersAction extends Action {
  type: ActionTypes.GET_ENHANCED_FILTERS;
  payload: {
    data: EnhancedFilters[];
  };
}

export interface SetEnhancedFilters extends Action {
  type: ActionTypes.SET_ENHANCED_FILTERS;
  payload: {
    data: Partial<EnhancedFilters>;
    filterName: string;
  };
}

export interface ClearEnhancedFilters extends Action {
  type: ActionTypes.CLEAR_ENHANCED_FILTERS;
}

export interface SetFilters extends Action {
  type: ActionTypes.SET_FILTERS;
  payload: {
    filters: Partial<Filters>;
  };
}
export interface SetPosition extends Action {
  type: ActionTypes.SET_POSITION;
  payload: {
    data: MapPosition;
  };
}

export interface SetMapInformation extends Action {
  type: ActionTypes.SET_MAP_INFO;
  payload: {
    mapInformation: Partial<MapInformation>;
  };
}

export interface LikeDemandAction extends Action {
  type: ActionTypes.LIKE_DEMAND;
  payload: {
    data: DemandLikeInformation;
  };
}
export interface SetLikeInformation extends Action {
  type: ActionTypes.SET_LIKE_INFORMATION;
  payload: {
    likeState: number;
    demandId: number;
    id: number;
    firstName: string;
    lastName: string;
    alias: string;
    pictureUrl: string;
  };
}

export type Actions =
  | GetMapInformationsAction
  | GetProducersAction
  | GetDeliveriesAction
  | SetFilters
  | GetEnhancedFiltersAction
  | ClearEnhancedFilters
  | GetCategoriesAction
  | GetUnitsAction
  | GetProductsAction
  | GetCommunityProductsAction
  | SetPosition
  | GetDemands2
  | GetLabels
  | GetCountiesAction
  | LikeDemandAction
  | SetLikeInformation
  | SetEnhancedFilters
  | GetProductsByCategoryAction
  | SetMapInformation;
