/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default menu-sub-hidden';
export const DEFAULT_ACTIVITY_TIMEOUT = 30000;
export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];

/* export const firebaseConfig = {
  : 'AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg',
  authDomain: 'gogo-react-login.firebaseapp.com',
  databaseURL: 'https://gogo-react-login.firebaseio.com',
  projectId: 'gogo-react-login',
  storageBucket: 'gogo-react-login.appspot.com',
  messagingSenderId: '216495999563',
}; */

export const searchPath = '#';
export const servicePath = 'https://api.coloredstrategies.com';

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = false;
export const defaultColor = 'light.greenlime';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isDemo = false;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];
export const selectedOrderOption = { column: 'default', label: 'Distanță' };

export const deliveryType = [
  {
    label: 'Livrare la domiciliu',
    value: 1,
    key: 1,
  },
  {
    label: 'Ridicare personală',
    value: 0,
    key: 2,
  },
];

export const deliveryTypeRidicare = [
  {
    label: 'Ridicare personală',
    value: 0,
    key: 2,
  },
];

export const deliveryTypeLivrare = [
  {
    label: 'Livrare la domiciliu',
    value: 1,
    key: 1,
  },
];

export const defaultImg = '/assets/img/detail.jpg';
export const numberCardsArray = [10, 8, 6, 4];

export const orderOptions = [
  { value: 'new', label: 'Noutate' },
  { value: 'priceUp', label: 'Preț crescător' },
  { value: 'priceDown', label: 'Preț descrescător' },
  { value: 'distance', label: 'Distanță' },
];

export const lastProductsObject = {
  limit: 10,
  orderBy: ['createdAt', 'DESC'],
};

export const howToOrderObject = {
  title: 'Cum funcționează',
  subTitle:
    'Bucură-te de produsele proaspete de la producători locali în 3 pași simplii.',
  firstTitle: 'Adaugă în coș',
  firstSubTitle: 'Adaugă în coș produsele preferate.',
  secondTitle: 'Selectează modul de livrare',
  secondSubTitle:
    'Selectează modul de livrare sau ridicare pentru comandă de la fiecare producător în parte.',

  thirdTitle: 'Finalizează comanda',
  thirdSubTitle:
    ' Adaugă datele de contact. Fiecare producător local de la care ai comandat te va contacta.',
};

export const becomeProducer = {
  title: 'Devino producător pe Micul Aprozar',
  subTitle:
    'Dacă ești producător local poți obține noi clienți punând la vânzare produsele proprii pe platforma Micul Aprozar.',
  firstTitle: 'Autentificare',
  firstSubTitle:
    'Intră în pagina de autentificare, alege un utilizator, o parolă și alege tipul de cont "Producător".',
  secondTitle: 'Introdu datele personale',
  secondSubTitle:
    'După crearea contului, introdu datele personale, detalii cu privire la metoda de livrare sau ridicare personală și produsele pe care dorești să le oferi consumatorilor, cu imagini și informații detaliate.',

  thirdTitle: 'Spor la vânzare!',
  thirdSubTitle:
    'In momentul în care primești o comandă vei fi notificat prin email și poți vizualiza comenzile tale din pagina administrativă a contului. Nu uita să confirmi adresa de email și să urmezi sugestiile din partea aplicației.',
};

export const userRedirect = {
  '-1': '/utilizator/autentificare',
  0: '/piata',
  1: '/cont/produsele-mele',
};

/////////////////////// TO DO: Change to this when Piata is up
// export const textLastBanner = {
//   '-1': {
//     title: 'Vinde produse',
//     subTitle:
//       'Crește vizibilitatea ta și a produselor tale în online prin intermediul Micului Aprozar.',
//   },
//   0: {
//     title: 'Piața Micul Aprozar ',
//     subTitle:
//       'Piața de legume/fructe s-a mutat în online. Depune acuma o cerere de produse in piața micul aprozar.',
//   },
//   1: {
//     title: 'Adaugă mai multe produse ',
//     subTitle: 'Adaugă mai multe produse pentru a crește volumul vânzărilor.',
//   },
// };

export const textLastBanner = {
  '-1': {
    title: 'Vinde produse',
    subTitle:
      'Crește vizibilitatea ta și a produselor tale în online prin intermediul Micului Aprozar.',
  },
  0: {
    title: 'Vezi producătorii din zona ta ',
    subTitle:
      'Prin harta interactiva poti vedea toti producatorii din zona ta.',
  },
  1: {
    title: 'Adaugă mai multe produse ',
    subTitle: 'Adaugă mai multe produse pentru a crește volumul vânzărilor.',
  },
};

/////////////////////// TO DO: Change to this when Piata is up
// export const textButtonLastBanner = {
//   '-1': 'Creează cont',
//   0: 'Cere produse',
//   1: 'Adaugă produse',
// };

export const textButtonLastBanner = {
  '-1': 'Creează cont',
  0: 'Vezi producătorii',
  1: 'Adaugă produse',
};

export let unitsData = [];
export let primaryCatData = [];

export const companyBillingMessage = {
  true: 'Factura va fi emisa pe Persoana Juridica',
  false: 'Factura va fi emisa pe Persoana Fizica',
};

export const commaWordsObjectHelper = {
  0: ['judet', 'localitate'],
  1: ['adresa', 'posta'],
  2: 'cui',
  3: 'registrulComertului',
};

export const messageAndRedirectHelper = (
  nullBillingData,
  fifteenDaysTillPay,
  isEmailConfirmed
) => {
  if (nullBillingData)
    return {
      message: 'Completeaza datele de facturare din profil',
      redirect: '/cont/adauga-card',
      reason: 'missingBillingInformation',
      button: 'Completează',
      isEmailConfirmed: isEmailConfirmed,
    };
  else if (fifteenDaysTillPay)
    return {
      message:
        'Contul a fost suspendat, produsele dvs. nu mai sunt disponibile in site. Efectuează plata pentru factura scadentă',
      redirect: '/cont/istoric-plati',
      reason: 'fifteenDaysTillPayment',
      button: 'Plătește',
      isEmailConfirmed: isEmailConfirmed,
    };
  else
    return {
      message: 'Account active',
      redirect: '',
      reason: '',
      button: '',
      isEmailConfirmed: -1,
    };
};

export const alertRenderHelper = (
  isSuspended,
  isConfirmed,
  message,
  redirect,
  button,
  reason
) => {
  const isEmailConfirmedObject = {
    color: isSuspended ? 'danger' : 'warning',
    message: 'Contul nu este confirmat!',
    redirect: '/utilizator/confirmare-email',
    button: 'Confirmă contul',
  };
  const isSuspendedObject = {
    color: 'danger',
    message: reason
      ? message
      : 'Contul a fost suspendat, produsele dvs. nu mai sunt disponibile in site. Pentru detalii contactați-ne la office@miculaprozar.ro sau +40774043791',
    redirect: redirect,
    button: button,
  };

  const alertRenderHelper =
    !isConfirmed && isSuspended
      ? [isEmailConfirmedObject, isSuspendedObject]
      : !isConfirmed
      ? [isEmailConfirmedObject]
      : isSuspended
      ? [isSuspendedObject]
      : null;

  return alertRenderHelper;
};

export const commaWordsSeparatorHelper = (words) => {
  const substringValue = words.indexOf(',');
  const wordBeforeComma = words.substring(0, substringValue);
  const wordAfterComma = words.substring(substringValue + 2, 99);

  return [wordBeforeComma, wordAfterComma];
};

export const billingStateInformationHelper = (billingDetails) => {
  const cui = billingDetails[2] ? billingDetails[2]?.value : '';
  const registrulComertului = billingDetails[3] ? billingDetails[3]?.value : '';
  const countyAndLocality = commaWordsSeparatorHelper(billingDetails[0]?.value);
  const county = countyAndLocality[0];
  const locality = countyAndLocality[1];
  const addressAndPostalCode = commaWordsSeparatorHelper(
    billingDetails[1].value
  );
  const address = addressAndPostalCode[0];
  const postalCode = addressAndPostalCode[1];
  return { cui, registrulComertului, county, locality, address, postalCode };
};

export const cardStyle = {
  hidePostalCode: true,
  style: {
    base: {
      color: '#32325d',
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#32325d',
      },
    },
    invalid: {
      fontFamily: 'Arial, sans-serif',
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};
