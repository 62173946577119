import { State } from './types';

const initialState: State = {
  user: {
    id: NaN,
    firstName: '',
    lastName: '',
    alias: '',
    isSeller: -1,
    phone: '',
    email: '',
    password: '',
    longitude: 0,
    latitude: 0,
    accessToken: '',
    address: '',
    description: '',
    deliveryTypeId: 1,
    pictureUrl: '',
    affiliateCode: '',
    isConfirmed: 0,
    isSuspended: 0,
    termsAndConditions: 0,
    newsletter: 1,
    DeliveryInfos: [],
  },
  products: [],
  cart: [],
  fetchedCart: false,
  fetchedDemands: false,
  demands: [],
};

export default initialState;
