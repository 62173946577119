import { Cart, ProductInCart } from 'api/map';
import produce from 'immer';
import _ from 'lodash';
import initialState from './initialState';
import { Actions, ActionTypes, State } from './types';

export default (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.LOGIN: {
      const { data, token } = action.payload;
      return produce(state, (draft) => {
        draft.user.email = data.email;
        draft.user.password = data.password;
        draft.user.accessToken = token;
      });
    }
    case ActionTypes.SIGN_UP: {
      const { data, token } = action.payload;
      return produce(state, (draft) => {
        draft.user.id = data.id;
        draft.user.email = data.email;
        draft.user.password = data.password;
        draft.user.accessToken = token;
        draft.user.firstName = data.firstName;
        draft.user.alias = data.alias;
        draft.user.affiliateCode = data.affiliateCode;
        draft.user.lastName = data.lastName;
        draft.user.phone = data.phone;
        draft.user.address = data.address;
        draft.user.isSeller = data.isSeller;
        draft.user.latitude = data.latitude;
        draft.user.longitude = data.longitude;
        draft.user.description = data.description;
        draft.user.DeliveryInfos = data.DeliveryInfos;
      });
    }
    case ActionTypes.SIGN_OUT: {
      return produce(state, (draft) => {
        draft.user = {
          id: NaN,
          firstName: '',
          lastName: '',
          alias: '',
          isSeller: -1,
          phone: '',
          email: '',
          password: '',
          longitude: 0,
          latitude: 0,
          accessToken: '',
          address: '',
          description: '',
          deliveryTypeId: 1,
          pictureUrl: '',
          affiliateCode: '',
          isConfirmed: 0,
          isSuspended: 0,
          termsAndConditions: 0,
          newsletter: 1,
          DeliveryInfos: [],
        };
      });
    }

    case ActionTypes.DELETE_STOCK: {
      const { id } = action.payload;
      return produce(state, (draft) => {
        _.dropWhile(draft.products, function (props) {
          return props.id !== id;
        });
      });
    }

    case ActionTypes.GET_DEMANDS: {
      const { data } = action.payload;
      const ret = produce(state, (draft) => {
        draft.demands = [];
        data &&
          data.forEach((element) => {
            draft.demands.push(element);
          });
        draft.fetchedDemands = true;
      });
      return ret;
    }

    case ActionTypes.GET_USER_INFORMATION: {
      const { data, token } = action.payload;

      return produce(state, (draft) => {
        const {
          id,
          firstName,
          lastName,
          alias,
          phone,
          email,
          address,
          latitude,
          longitude,
          products,
          description,
          isSeller,
          deliveryTypeId,
          pictureUrl,
          affiliateCode,
          isConfirmed,
          isSuspended,
          termsAndConditions,
          DeliveryInfos,
          newsletter,
        } = data[0];
        draft.user = {
          id: id,
          firstName: firstName,
          lastName: lastName,
          alias: alias,
          isSeller: isSeller !== undefined ? isSeller : -1,
          phone: phone || '',
          email: email || '',
          password: '',
          longitude: longitude,
          latitude: latitude,
          accessToken: token,
          address: address,
          description: description,
          deliveryTypeId: deliveryTypeId,
          pictureUrl: pictureUrl,
          affiliateCode: affiliateCode,
          isConfirmed: isConfirmed,
          isSuspended: isSuspended,
          termsAndConditions: termsAndConditions,
          newsletter: newsletter,
          DeliveryInfos: DeliveryInfos,
        };
        draft.products = products;
      });
    }

    case ActionTypes.UPDATE_USER_INFORMATION: {
      const { data, token } = action.payload;
      return produce(state, (draft) => {
        const userInformation = state.user;
        draft.user = {
          id: userInformation.id,
          firstName: data.firstName || userInformation.firstName,
          lastName: data.lastName || userInformation.lastName,
          alias: data.alias || userInformation.alias,
          isSeller: data.isSeller || 0,
          phone: data.phone || userInformation.phone,
          email: data.email || userInformation.email,
          password: '',
          longitude: data.longitude || userInformation.longitude,
          latitude: data.latitude || userInformation.latitude,
          accessToken: token,
          address: data.address || userInformation.address,
          description: data.description || userInformation.description,
          deliveryTypeId: data.deliveryTypeId || userInformation.deliveryTypeId,
          pictureUrl: data.pictureUrl || userInformation.pictureUrl,
          affiliateCode: data.affiliateCode || userInformation.affiliateCode,
          isConfirmed: userInformation.isConfirmed,
          isSuspended: userInformation.isSuspended,
          newsletter: userInformation.newsletter,
          termsAndConditions: userInformation.termsAndConditions,
          DeliveryInfos: data.DeliveryInfos || userInformation.DeliveryInfos,
        };
      });
    }
    case ActionTypes.UPDATE_DEMAND_INFORMATION: {
      const { data, id, categories } = action.payload;
      return produce(state, (draft) => {
        let demandExists = false;

        if (data.isDeleted) {
          draft.demands = draft.demands.filter((product) => id !== product.id);
        } else {
          draft.demands.forEach((product, index) => {
            if (product.id === id) {
              demandExists = true;
              draft.demands[index] = {
                id: id,
                name: data.name,
                price: data.price,
                phone: data.phone,
                counties: data.counties,
                createdAt: data.createdAt || '',
                updatedAt: '',
                categoryId: data.categoryId,
                userId: 1,
                quantity: data.quantity,
                isDeleted: 0,
                description: data.description || '',
                isAvailable: data.isAvailable,
                Category: categories.find(
                  (element) => element.id === data.categoryId
                ) || { id: NaN, name: '', parentId: NaN },

                User: {
                  id: 0,
                  firstName: '',
                  lastName: '',
                  pictureUrl: '',
                  alias: '',
                  affiliateCode: '',

                  isConfirmed: 0,
                  isSuspended: 1,
                  termsAndConditions: 1,
                  newsletter: 1,
                },
                likes: [],
                liked: [],
              };
            }
          });
          if (!demandExists) {
            draft.demands.push({
              id: id,
              name: data.name,
              price: data.price,
              phone: data.phone,
              counties: data.counties,
              createdAt: data.createdAt || '',
              updatedAt: '',
              categoryId: data.categoryId,
              userId: 1,
              quantity: data.quantity,
              isDeleted: 0,
              description: data.description || '',
              isAvailable: data.isAvailable,
              Category: categories.find(
                (element) => element.id === data.categoryId
              ) || { id: NaN, name: '', parentId: NaN },

              User: {
                id: 0,
                firstName: '',
                lastName: '',
                pictureUrl: '',
                alias: '',
                affiliateCode: '',

                isConfirmed: 0,
                isSuspended: 1,
                termsAndConditions: 1,
                newsletter: 1,
              },
              likes: [],
              liked: [],
            });
          }
        }
      });
    }
    case ActionTypes.UPDATE_STOCK_INFORMATION: {
      const { data, categories, measurements } = action.payload;
      return produce(state, (draft) => {
        let productExists = false;
        if (data.isDeleted) {
          draft.products = draft.products.filter(
            (product) => data.id !== product.id
          );
        } else {
          draft.products.forEach((product, id) => {
            if (product.id === data.id) {
              productExists = true;
              draft.products[id] = {
                id: data.id,
                name: data.name,
                price: data.price,
                createdAt: data.createdAt || '',
                quantity: data.quantity,
                description: data.description || '',
                hasImage: data.hasImage,
                isAvailable: data.isAvailable,
                Category: categories.find(
                  (element) => element.id === data.CategoryId
                ) || { id: NaN, name: '', parentId: NaN },
                MeasurementUnit: measurements.find(
                  (element) => element.id === data.MeasurementUnitId
                ) || { id: NaN, name: '' },
                User: {
                  affiliateCode: '',
                  id: 0,
                  firstName: '',
                  lastName: '',
                  alias: '',
                  phone: '',
                  email: '',
                  description: '',
                  address: '',
                  longitude: 0,
                  latitude: 0,
                  reviewsReceivedNumber: 0,
                  reviewsReceivedPoints: 0,
                  overallRating: 0,
                  deliveryTypeId: 1,
                  pictureUrl: '',
                  distance: 0,
                },
              };
            }
          });

          if (!productExists) {
            draft.products.push({
              id: data.id,
              name: data.name,
              createdAt: data.createdAt,
              price: data.price,
              quantity: data.quantity,
              description: data.description || '',
              hasImage: data.hasImage,
              isAvailable: data.isAvailable,
              Category: categories.find(
                (element) => element.id === data.CategoryId
              ) || { id: NaN, name: '', parentId: NaN },
              MeasurementUnit: measurements.find(
                (element) => element.id === data.MeasurementUnitId
              ) || { id: NaN, name: '' },
              User: {
                affiliateCode: '',
                id: 0,
                firstName: '',
                lastName: '',
                alias: '',
                description: '',
                phone: '',
                email: '',
                address: '',
                longitude: 0,
                latitude: 0,
                reviewsReceivedNumber: 0,
                reviewsReceivedPoints: 0,
                overallRating: 0,
                deliveryTypeId: 1,
                pictureUrl: '',
                distance: 0,
              },
            });
          }
        }
      });
    }

    case ActionTypes.INSERT_CART: {
      const { data } = action.payload;
      return produce(state, (draft) => {
        const foundSeller = draft.cart.findIndex(
          (seller) => seller.userId === data.userId
        );

        if (foundSeller !== -1) {
          const foundProduct = draft.cart[foundSeller].products.findIndex(
            (product) => product.id === data.products[0].id
          );
          if (foundProduct !== -1) {
            draft.cart[foundSeller].products[foundProduct].quantity +=
              data.products[0].quantity;
          } else {
            draft.cart[foundSeller].products.push(data.products[0]);
          }
        } else {
          draft.cart.push(data);
        }
      });
    }

    case ActionTypes.EMPTY_CART: {
      return produce(state, (draft) => {
        draft.cart = [];
        draft.fetchedCart = false;
      });
    }

    case ActionTypes.GET_CART: {
      const { data } = action.payload;
      let newcart: Cart[] = [];

      let finishedCart = produce(state, (draft) => {
        if (data.data) {
          data.data.products.forEach((cartProduct) => {
            const productToInsert: ProductInCart = {
              Category: cartProduct.Category,
              MeasurementUnit: cartProduct.MeasurementUnit,
              description: cartProduct.description,
              hasImage: cartProduct.hasImage,
              id: cartProduct.id,
              name: cartProduct.name,
              price: cartProduct.price,
              quantity: cartProduct.CartProduct.quantity,
            };
            let index = newcart
              ? newcart.findIndex((x) => x.userId === cartProduct.User.id)
              : -1;
            if (index !== -1) {
              newcart[index].products.push(productToInsert);
            } else {
              const ce = {
                userId: cartProduct.User.id,
                firstName: cartProduct.User.firstName,
                lastName: cartProduct.User.lastName,
                alias: cartProduct.User.alias,
                products: [productToInsert],
                deliveryTypeId: cartProduct.User.deliveryTypeId,
                deliveryInfos: cartProduct.User.DeliveryInfos,
              };
              newcart.push(ce);
            }
          });
          draft.cart = newcart;
          draft.fetchedCart = true;
        } else if (data.status === 200) {
          draft.fetchedCart = true;
        } else {
          draft.fetchedCart = false;
        }
      });

      return finishedCart;
    }

    case ActionTypes.GET_CART_ANONYMOUS: {
      const { data } = action.payload;

      return produce(state, (draft) => {
        draft.cart = data;
      });
    }

    default: {
      return state;
    }
  }
};
