import { combineReducers } from 'redux';
import { reducer as account, State as Account } from './account';
import { reducer as map, State as Map } from './map';
import { reducer as meta, State as Meta } from './meta';
import { reducer as info_pages, State as InfoPages } from './infoPages';
import { reducer as stripe, State as Stripe } from './stripe';

import { reducer as menu, State as Menu } from './menu';
import { reducer as actitivies, State as Activities } from './request';

export interface ApplicationState {
  configs: {
    meta: Meta;
    info_pages: InfoPages;
  };
  menu: Menu;
  stripe: Stripe;
  data: {
    map: Map;
    account: Account;
  };
  request: {
    actitivies: Activities;
  };
}

export default combineReducers<ApplicationState>({
  configs: combineReducers({
    meta,
    info_pages,
  }),
  menu,
  stripe,
  data: combineReducers({
    map,
    account,
  }),
  request: combineReducers({
    actitivies,
  }),
});
