import { apiFactory } from 'api';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import reducers from './root';

export const configureStore = () => {
  const api = apiFactory();
  const middlewares = composeWithDevTools(
    applyMiddleware(thunk.withExtraArgument({ api }))
  );
  const store = createStore(reducers, middlewares);
  return store;
};
