import { createSelector } from 'reselect';
import { State } from './types';

export const selectStripeData = createSelector(
  (state: State) => {
    return state;
  },
  (stripeData) => stripeData
);

export const selectStripeDataIsFetched = createSelector(
  (state: State) => {
    return state.message === '' ? false : true;
  },
  (fetched) => fetched
);
